















































































































































































































































































































































































































































































import { Component, Mixins } from "vue-property-decorator";
import EditSubAccountComponent from "./EditSubAccount.component";
@Component
export default class EditSubAccount extends Mixins(EditSubAccountComponent) {}
